import '@/assets/admin-css/dashboard.css';
import baseComponent from "@/scripts/baseComponent";
import usersApi from '@/api/common/users';
import chartDataApi from '@/api/common/chartData';
import vmMachineApi from '@/api/gpuHub/vmMachines';
import { mapState } from "vuex";
import Highcharts from 'highcharts'
import { colorGpuNodeColor, vmMachineStatus, vmMachineStatusColor } from "@/constant/vmMachineStatusConst";
import {
    countSpline, areaSplineChart, bootingMachine, totalRechargeOptions, totalUsingTimeChartOptions, circleChart,
    colorSun, colorSat, colorNormal
}
    from "@/constant/dashboardChartOptions";
import { Chart } from 'highcharts-vue';
import { arrayToObject } from "@/utils"
var jsonToPivotjson = require("json-to-pivot-json");
import moment from "moment";
var numeral = require('numeral');
export default {
    extends: baseComponent,
    components: {
        highcharts: Chart
    },
    data() {
        return {
            tabStyle: 'height: calc(100dvh - 12rem);',
            intervalUpdateChart: null,
            colorGpuNodeColor: colorGpuNodeColor,
            vmMachineStatus: vmMachineStatus,
            vmMachineStatusColor: vmMachineStatusColor,
            numberOfDateView: 30,
            userSourceFilterList: [],
            softwareList: [],
            categoryList: [],
            typeViewMasterNode: 'category',
            selectedSoftware: '',
            selectedCategory: '',
            countUserRechargeByLevel: {
                userSource: '',
                seriesList : [
                    { id: 'countUser0_99', name: '0-$99' },
                    { id: 'countUser100_500', name: '$100-$500' },
                    { id: 'countUser500_1000', name: '$500-$1000' },
                    { id: 'countUserMore1000', name: '> $1000' }
                ],
                serieSelected: '',
                options: Object.assign(JSON.parse(JSON.stringify(countSpline)), {
                    plotOptions: {
                        spline: {
                            dataLabels: {
                                enabled: true
                            },
                        },
                        series: {
                            tooltip: {
                                headerFormat: '{point.x}<br>',
                                pointFormat: 'Recharge <b>{series.name}</b> {point.y} times',
                                clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
                            },
                        },
                    },
                }),
                tableData: [],
            },
            countMasterNodeByCategory: {
                options: Object.assign(JSON.parse(JSON.stringify(countSpline)), {
                    plotOptions: {
                        series: {
                            tooltip: {
                                headerFormat: '{point.x} <br>',
                                pointFormat: '{point.y} Master node <b>{series.name}</b> created',
                                clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
                            },
                        },
                    }
                }),
                tableData: [],
            },
            countMasterNodeBySoftware: {
                options: Object.assign(JSON.parse(JSON.stringify(countSpline)), {
                    plotOptions: {
                        series: {
                            tooltip: {
                                headerFormat: '{point.x} <br>',
                                pointFormat: '{point.y} Master node <b>{series.name}</b> created',
                                clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
                            },
                        },
                    }
                }),
                tableData: [],
            },
            percentMasterNodeByCategory: {
                options: JSON.parse(JSON.stringify(circleChart)),
                tableData: [],
            },
            percentMasterNodeBySoftware: {
                options: JSON.parse(JSON.stringify(circleChart)),
                tableData: [],
            },
            usedMachineChart: {
                targetServiceType: "",
                options: Object.assign(JSON.parse(JSON.stringify(countSpline)), {

                }),
                tableData: [],
            },
            bootingMachineChart: {
                targetServiceType: "",
                options: Object.assign({}, bootingMachine),
                tableData: [],
            },
            userRechargeMoreChart: {
                options: Object.assign({}, areaSplineChart),
                tableData: [],
            },
            machineCreatedChart: {
                options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)),
                {
                    legend: { enabled: false },
                }),
                tableData: [],
            },
            totalRechargeChart: {
                providerList: ['BANKING', 'OTHERS'],
                options: Object.assign({}, totalRechargeOptions),
                tableData: [],
            },
            totalUsingTimeChart: {
                options: Object.assign(JSON.parse(JSON.stringify(totalUsingTimeChartOptions)),
                    {
                        legend: { enabled: false },
                    }),
                tableData: [],
            },
            userBlacklistChart: {
                options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                    legend: { enabled: false },
                    plotOptions: {
                        areaspline: {
                            fillOpacity: 0.5
                        }
                    },
                }),
                tableData: [],
            },
            userPaidChart: {
                domesticOrInternational: 'all',
                options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                    yAxis: [{ // Primary yAxis
                        labels: {
                            format: '{value} user(s)',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        title: {
                            text: 'User quantity',
                            style: {
                                color: Highcharts.getOptions().colors[1],
                                "font-size" : 15,
                            }
                        }
                    },{ // Secondary yAxis
                        title: {
                            text: 'Total Amount',
                            style: {
                                "font-size": 15,
                            }
                        },
                        labels: {
                            format: '${value}',
                        },
                        opposite: true
                    },],
                    tooltip: {
                        shared: true
                    },
                    plotOptions: {
                        areaspline: {
                            dataLabels: {
                                enabled: true
                            },
                            fillOpacity: 0.5
                        }
                    },
                }),
                tableData: [],
            },
        };
    },
    computed: {        
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
        }),
        //masterNodeChartData() {
        //    if (this.typeViewMasterNode === 'category') {
        //        return this.countMasterNodeByCategory;
        //    }
        //    else return this.countMasterNodeBySoftware;
        //},
        //masterNodePercentChartData() {
        //    if (this.typeViewMasterNode === 'category') {
        //        return this.percentMasterNodeByCategory;
        //    }
        //    else return this.percentMasterNodeBySoftware;
        //}
    },
    mounted() {
        this.loadRentalServerStatus();
        this.getUserSource();
        this.getAllCategory();
        this.getAllSoftware();
        this.getUserRechargeMoreThanOneChartData();
        this.getMachineCreatedChartData();
        this.getTotalRechargeChartData();
        this.getTotalUsingTimeChartData();
        this.getCountBlackListUser();
        this.getCountPaidUserWithRecharge();
        this.intervalUpdateChart = setInterval(() => {
            this.refreshChartData();
        }, 60000);
    },
    beforeDestroy() {
        clearInterval(this.intervalUpdateChart);
    },
    methods: {
        loadRentalServerStatus() {
            vmMachineApi.getAllServerReport().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    let packageIdShowing = this.$store.state.common.availabilityServiceList.map(x => x.package);
                    let packageFilter = response.data.data.data.filter(x => packageIdShowing.includes(x.package));
                    this.serverStatus = packageFilter.map(item => {
                        return {
                            "package": item.package,
                            "total": item.total,
                            "available": item.available,
                            "status_text": item.statusText
                        };
                    });
                    setTimeout(() => {
                        this.getMachineUserdChartData();
                        this.getMachineBootingChartData();
                    }, 500);
                    this.hideLoading();
                }
                else {
                    this.serverStatus = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.serverStatus = [];
            });
        },
        getUserSource() {
            usersApi.getUserSource().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.userSourceFilterList = response.data.data;
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getAllCategory() {
            vmMachineApi.getAllCategory().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.categoryList = [{ id : -1, name : "BLANK" }].concat(response.data.data);
                    //this.getCountMasterNodeByCategory();
                    //this.getPercentMasterNodeByCategory();
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getAllSoftware() {
            vmMachineApi.getAllSoftware().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.softwareList = [{ id: -1, name: "BLANK" }].concat(response.data.data);
                }
            }).catch(error => {
                console.error(error);
            });
        },
        refreshChartData() {
            this.getCountUserRechargeByLevel();
            //this.refreshMasterNodeChartData();

            this.getMachineUserdChartData();
            this.getMachineBootingChartData();
            //===============================
            this.getMachineCreatedChartData();
            this.getUserRechargeMoreThanOneChartData();
            this.getTotalRechargeChartData();
            this.getTotalUsingTimeChartData();
            this.getCountBlackListUser();
            this.getCountPaidUserWithRecharge();
        },
        refreshMasterNodeChartData() {
            if (this.typeViewMasterNode === 'category') {
                this.getCountMasterNodeByCategory();
                this.getPercentMasterNodeByCategory();
            }
            else {
                this.getCountMasterNodeBySoftware();
                this.getPercentMasterNodeBySoftware();
            }
        },
        getCountUserRechargeByLevel() {
            chartDataApi.getCountUserRechargeByLevel(this.numberOfDateView, this.countUserRechargeByLevel.userSource).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.countUserRechargeByLevel.tableData = response.data.data;
                    this.countUserRechargeByLevel.options.xAxis.categories = [];
                    this.countUserRechargeByLevel.options.xAxis.categories = response.data.data.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.countUserRechargeByLevel.options.series = [];
                    let seriesFilter = this.countUserRechargeByLevel.seriesList.filter(x => (this.countUserRechargeByLevel.serieSelected === '' || x.id.toString() === this.countUserRechargeByLevel.serieSelected))
                    for (let i = 0; i < seriesFilter.length; i++) {
                        this.countUserRechargeByLevel.options.series.push({
                            name: seriesFilter[i].name,
                            data: response.data.data.map(x => {
                                return {
                                    y: x[seriesFilter[i].id],
                                    marker: {
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                };
                            })
                        });
                    }
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        //==========================================================================
        getCountMasterNodeByCategory() {
            chartDataApi.getCountMasterNodeByCategory(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    var options = {
                        row: "createdAt",
                        column: "categoryId",
                        value: "countNode"
                    };
                    var output = jsonToPivotjson(response.data.data, options);
                    this.countMasterNodeByCategory.tableData = output;
                    this.countMasterNodeByCategory.options.xAxis.categories = [];
                    this.countMasterNodeByCategory.options.xAxis.categories = output.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.countMasterNodeByCategory.options.series = [];
                    let categoryFilter = this.categoryList.filter(x => (this.selectedCategory.toString() === '' || x.id.toString() === this.selectedCategory.toString()));
                    for (let i = 0; i < categoryFilter.length; i++) {
                        this.countMasterNodeByCategory.options.series.push({
                            name: categoryFilter[i].name,
                            data: output.map(x => {
                                return {
                                    y: x[categoryFilter[i].id],
                                    marker: {
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                };
                            })
                        });
                    }
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        //==========================================================================
        getCountMasterNodeBySoftware() {
            chartDataApi.getCountMasterNodeBySoftware(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    var options = {
                        row: "createdAt",
                        column: "softwareId",
                        value: "countNode"
                    };
                    var output = jsonToPivotjson(response.data.data, options);
                    this.countMasterNodeBySoftware.tableData = output;
                    this.countMasterNodeBySoftware.options.xAxis.categories = [];
                    this.countMasterNodeBySoftware.options.xAxis.categories = output.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.countMasterNodeBySoftware.options.series = [];
                    let softwareFilter = this.softwareList.filter(x => (this.selectedSoftware.toString() === '' || x.id.toString() === this.selectedSoftware.toString()));
                    for (let i = 0; i < softwareFilter.length; i++) {
                        this.countMasterNodeBySoftware.options.series.push({
                            name: softwareFilter[i].name,
                            data: output.map(x => {
                                return {
                                    y: x[softwareFilter[i].id],
                                    marker: {
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                };
                            })
                        });
                    }
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getPercentMasterNodeByCategory() {
            chartDataApi.getPercentMasterNodeByCategory(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.percentMasterNodeByCategory.tableData = response.data.data;
                    let categoryFilter = arrayToObject(this.categoryList, 'id');
                    this.percentMasterNodeByCategory.options.series = [{
                        name: "Proportion",
                        data: response.data.data.map(x => {
                            return { name: categoryFilter[x.categoryId].name, y: x.countNode };
                        })
                    }];
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        //=========================================================================={ name: 'Chrome', y: 61.41 }
        getPercentMasterNodeBySoftware() {
            chartDataApi.getPercentMasterNodeBySoftware(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.percentMasterNodeBySoftware.tableData = response.data.data;
                    let softwareFilter = arrayToObject(this.softwareList, 'id');
                    this.percentMasterNodeBySoftware.options.series = [{
                        name: "Proportion",
                        data: response.data.data.map(x => {
                            return { name: softwareFilter[x.softwareId].name, y: x.countNode };
                        })
                    }];
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        //===============================================
        getMachineUserdChartData() {
            chartDataApi.getMachineUserdChartData(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    var options = {
                        row: "transAt",
                        column: "packageId",
                        value: "countPackage"
                    };
                    var output = jsonToPivotjson(response.data.data, options);
                    this.usedMachineChart.tableData = output;
                    this.usedMachineChart.options.xAxis.categories = [];
                    this.usedMachineChart.options.xAxis.categories = output.map(x => moment.utc(x.transAt).format('DD-MM-YYYY'));
                    this.usedMachineChart.options.series = [];

                    let packageIdShowing = this.$store.state.common.availabilityServiceList.map(x => x.package);
                    let packageFilter = this.$store.state.gpuNode.filter(x => packageIdShowing.includes(x.id)  && (this.usedMachineChart.targetServiceType === '' || x.serviceType.toString() === this.usedMachineChart.targetServiceType))

                    for (let i = 0; i < packageFilter.length; i++) {
                        this.usedMachineChart.options.series.push({
                            name: (this.$store.state.gpuNodeName[packageFilter[i].id]) ? this.$store.state.gpuNodeName[packageFilter[i].id].name : packageFilter[i].id,
                            data: output.map(x => {
                                return {
                                    y: x[packageFilter[i].id],
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.transAt).day() === 0) ? colorSun : ((moment.utc(x.transAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                };
                            })
                        });
                    }
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getMachineBootingChartData() {
            chartDataApi.getMachineBootingChartData(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    var options = {
                        row: "transAt",
                        column: "packageId",
                        value: "countPackage"
                    };
                    var output = jsonToPivotjson(response.data.data, options);
                    this.bootingMachineChart.tableData = output;
                    this.bootingMachineChart.options.xAxis.categories = [];
                    this.bootingMachineChart.options.xAxis.categories = output.map(x => moment.utc(x.transAt).format('DD-MM-YYYY'));
                    this.bootingMachineChart.options.series = [];
                    let packageFilter = this.$store.state.gpuNode.filter(x => (this.bootingMachineChart.targetServiceType === '' || x.serviceType.toString() === this.bootingMachineChart.targetServiceType))
                    for (let i = 0; i < packageFilter.length; i++) {
                        this.bootingMachineChart.options.series.push({
                            name: (this.$store.state.gpuNodeName[packageFilter[i].id]) ? this.$store.state.gpuNodeName[packageFilter[i].id].name : packageFilter[i].id,
                            data: output.map(x => {
                                return {
                                    y: (x[packageFilter[i].id] ? x[packageFilter[i].id] : 0),
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.transAt).day() === 0) ? colorSun : ((moment.utc(x.transAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                };
                            })
                        });
                    }
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getUserRechargeMoreThanOneChartData() {
            chartDataApi.getUserRechargeMoreThanOneChartData(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {

                    this.userRechargeMoreChart.tableData = response.data.data;
                    this.userRechargeMoreChart.options.xAxis.categories = [];
                    this.userRechargeMoreChart.options.xAxis.categories = response.data.data.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.userRechargeMoreChart.options.series = [];
                    this.userRechargeMoreChart.options.series.push({
                        name: "User rechare more than 1 time",
                        data: response.data.data.map(x => {
                            return {
                                y: x.countUser,
                                marker: {
                                    //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                    fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                }
                            }
                        })
                    });
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getMachineCreatedChartData() {
            chartDataApi.getMachineCreatedChartData(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {

                    this.machineCreatedChart.tableData = response.data.data;
                    this.machineCreatedChart.options.xAxis.categories = [];
                    this.machineCreatedChart.options.xAxis.categories = response.data.data.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.machineCreatedChart.options.series = [];
                    this.machineCreatedChart.options.series.push({
                        name: "Image created",
                        data: response.data.data.map(x => {
                            return {
                                y: x.countVm,
                                marker: {
                                    //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                    fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                }
                            }
                        })
                    });
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getTotalRechargeChartData() {
            chartDataApi.getTotalRechargeChartData(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    var options = {
                        row: "createdAt",
                        column: "provider",
                        value: "totalAmount"
                    };
                    var output = jsonToPivotjson(response.data.data, options);
                    this.totalRechargeChart.tableData = output;
                    this.totalRechargeChart.options.xAxis.categories = [];
                    this.totalRechargeChart.options.xAxis.categories = output.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.totalRechargeChart.options.series = [];
                    for (let i = 0; i < this.totalRechargeChart.providerList.length; i++) {
                        this.totalRechargeChart.options.series.push({
                            name: this.totalRechargeChart.providerList[i],
                            data: output.map(x => {
                                return {
                                    y: x[this.totalRechargeChart.providerList[i]],
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                };
                            })
                        });
                    }
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getTotalUsingTimeChartData() {
            chartDataApi.getTotalUsingTimeChartData(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.totalUsingTimeChart.tableData = response.data.data;
                    this.totalUsingTimeChart.options.xAxis.categories = [];
                    this.totalUsingTimeChart.options.xAxis.categories = response.data.data.map(x => moment.utc(x.transAt).format('DD-MM-YYYY'));
                    this.totalUsingTimeChart.options.series = [];
                    this.totalUsingTimeChart.options.series.push({
                        name: "Total using time",
                        data: response.data.data.map(x => {
                            return {
                                y: x.totalUsingTimeInHour,
                                marker: {
                                    //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                    fillColor: (moment.utc(x.transAt).day() === 0) ? colorSun : ((moment.utc(x.transAt).day() === 6) ? colorSat : colorNormal)
                                }
                            }
                        })
                    });
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getCountBlackListUser() {
            chartDataApi.getCountBlackListUser(this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.userBlacklistChart.tableData = response.data.data;
                    this.userBlacklistChart.options.xAxis.categories = [];
                    this.userBlacklistChart.options.xAxis.categories = response.data.data.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.userBlacklistChart.options.series = [];
                    this.userBlacklistChart.options.series.push({
                        name: "Blacklist user",
                        data: response.data.data.map(x => {
                            return {
                                y: x.countUser,
                                marker: {
                                    //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                    fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                }
                            }
                        })
                    });
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getCountPaidUserWithRecharge() {
            chartDataApi.getCountPaidUserWithRecharge(this.userPaidChart.domesticOrInternational, this.numberOfDateView).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.userPaidChart.tableData = response.data.data;
                    this.userPaidChart.options.xAxis.categories = [];
                    this.userPaidChart.options.xAxis.categories = response.data.data.map(x => moment.utc(x.createdAt).format('DD-MM-YYYY'));
                    this.userPaidChart.options.series = [
                        {
                            name: "Amount recharge",
                            yAxis: 1,
                            type: 'column',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: '$'
                            },
                            data: response.data.data.map(x => {
                                return {
                                    y: x.totalAmount,
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                }
                            })
                        },
                        {
                            name: "Paid user",
                            type: 'spline',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valueSuffix: ' user(s)'
                            },
                            data: response.data.data.map(x => {
                                return {
                                    y: x.countUser,
                                    marker: {
                                        //symbol: moment.utc(x.createdAt).day() === 0 ? 'url(https://www.highcharts.com/samples/graphics/sun.png)' : null
                                        fillColor: (moment.utc(x.createdAt).day() === 0) ? colorSun : ((moment.utc(x.createdAt).day() === 6) ? colorSat : colorNormal)
                                    }
                                }
                            })
                        }];
                }
                else {
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        arrayRechargeToHTMLTable(myArray, dateColName) {
            if (myArray.length > 0) {
                var result = "<table class='table table-bordered table-striped'>";
                let keyList = Object.keys(myArray[0]);
                //Create header
                result += "<tr>";
                result += `<td class="text-center p-1"><h5 class="mb-0"><strong>Date</strong></h4></td>`;
                for (let j = 0; j < this.totalRechargeChart.providerList.length; j++) {
                    result += `<td class="text-right p-1"><h5 class="mb-0"><strong>${this.totalRechargeChart.providerList[j]}</strong></h4></td>`;
                }
                result += "</tr>";
                for (let i = 0; i < myArray.length; i++) {
                    result += "<tr>";
                    result += `<td class="text-center p-1"><h5 class="mb-0">${moment.utc(myArray[i][(dateColName || keyList[0])]).format('DD-MM-YYYY')}</h5></td>`;
                    for (let j = 0; j < this.totalRechargeChart.providerList.length; j++) {
                        result += `<td  class="text-right p-1">
                                    <h5 class="mb-0">${(myArray[i][this.totalRechargeChart.providerList[j]] ?
                                (myArray[i][this.totalRechargeChart.providerList[j]] > 0 ? '$' + myArray[i][this.totalRechargeChart.providerList[j]] : "-") : "-")}</h5>
                            </td>`;
                    }
                }
                //Create foooter
                result += "<tr>";
                result += `<td class="text-center p-1"><h5 class="mb-0"><strong>Total</strong></h4></td>`;
                for (let j = 0; j < this.totalRechargeChart.providerList.length; j++) {
                    let sumVal = myArray.reduce((total, num) => { return total + num[this.totalRechargeChart.providerList[j]]; }, 0);
                    result += `<td class="text-right p-1"><h5 class="mb-0"><strong>${numeral(sumVal).format('0,0[.][0000]')}</strong></h4></td>`;
                }
                result += "</table>";
                return result;
            }
            else return "";
        },
        arrayToHTMLTable(myArray, dateColName) {
            if (myArray.length > 0) {
                var result = "<table class='table table-bordered table-striped'>";
                let keyList = Object.keys(myArray[0]);
                //Create header
                result += "<tr>";
                result += `<td class="text-center p-1"><h5 class="mb-0"><strong>Date</strong></h4></td>`;
                for (var j = 0; j < this.serverStatus.length; j++) {
                    result += `<td class="text-center p-1"><h5 class="mb-0"><strong>${this.$store.state.gpuNodeName[this.serverStatus[j].package].name}</strong></h4></td>`;
                }
                result += "</tr>";
                for (var i = 0; i < myArray.length; i++) {
                    result += "<tr>";
                    result += `<td class="text-center p-1"><h5 class="mb-0">${moment.utc(myArray[i][(dateColName || keyList[0])]).format('DD-MM-YYYY') }</h5></td>`;
                    for (j = 0; j < this.serverStatus.length; j++) {
                        result += `<td  class="text-center p-1">
                                    <h5 class="mb-0">${(myArray[i][this.serverStatus[j].package] ?
                                (myArray[i][this.serverStatus[j].package] > 0 ? myArray[i][this.serverStatus[j].package] + ' times' : "-") : "-")}</h5>
                            </td>`;
                    }
                }
                //Create foooter
                result += "<tr>";
                result += `<td class="text-center p-1"><h5 class="mb-0"><strong>Total</strong></h4></td>`;
                for (let j = 0; j < this.serverStatus.length; j++) {
                    let sumVal = myArray.reduce((total, num) => { return total + num[this.serverStatus[j].package]; }, 0);
                    result += `<td class="text-center p-1"><h5 class="mb-0"><strong>${numeral(sumVal).format('0,0[.][0000]')}${sumVal > 0 ? ' times' : ' time'}</strong></h4></td>`;
                }
                result += "</table>";
                return result;
            }
            else return "";
        }   
    }
}